var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-item',{class:{
		'settings-item-wrapper': true,
		'rounded-borders': true,
		'justify-between': true,
		'relative-position': true,
		'light-dimmed': _vm.loading,
		'cursor-not-allowed': _vm.loading,
		'row': _vm.$q.screen.gt.sm,
		'column': _vm.$q.screen.lt.sm,
	}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(_vm.item.text))])],1),_c('q-item-section',{class:_vm.$q.screen.lt.sm ? 'no-padding' : '',attrs:{"avatar":""}},[_c('q-item-label',[_vm._v(" "+_vm._s(_vm.value)+" "),(_vm.loading)?_c('q-spinner-ios',{staticClass:"dimmed",attrs:{"size":"2rem","color":"primary"}}):_vm._e(),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"outline":"","label":"unlock","color":"primary","disable":_vm.disabled},on:{"click":_vm.unlock}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }