<template>
	<q-item tag="label"
		:class="slider_item_class"
	>
		<q-item-section>
			<q-item-label>{{ item.text }}</q-item-label>
		</q-item-section>

		<q-item-section avatar>
			<q-toggle v-model="value" />
		</q-item-section>
	</q-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SliderItem',
	props: {
		item: Object
	},
	data(){
		return {
			slider_item_class: {
				'settings-item-wrapper': true,
				'rounded-borders': true,
				'row': true,
				'justify-between': true,
				'relative-position': true,
			}
		}
	},
	computed: {
		...mapGetters(['user_instance']),
		value: {
			get() {
				return this.user_instance[this.item.attribute]
			},
			set(val) {
				this.updateUser({[this.item.attribute]: val})
			},
		},
	},
	methods: {
		...mapActions(['updateUser']),
	},
	created(){
		if (this.item.highlight_item){
			this.slider_item_class['highlight_item'] = true
		}
	},
}
</script>

