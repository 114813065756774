<template>
	<q-item 
		:class="{
			'settings-item-wrapper': true,
			'rounded-borders': true,
			'justify-between': true,
			'relative-position': true,
			'light-dimmed': loading,
			'cursor-not-allowed': loading,
			'row': $q.screen.gt.sm,
			'column': $q.screen.lt.sm,
		}"
	>
		<q-item-section>
			<q-item-label>{{ item.text }}</q-item-label>
		</q-item-section>

		<q-item-section avatar :class="$q.screen.lt.sm ? 'no-padding' : ''">
			<q-item-label>
				{{ value }}
				<q-spinner-ios v-if="loading" size="2rem" color="primary" class="dimmed"/>
				<q-btn
					outline
					label="unlock"
					color="primary"
					@click="unlock"
					class="q-ml-sm"
					:disable="disabled"
				/>
			</q-item-label>
		</q-item-section>
	</q-item>
</template>

<script>
import { mapGetters } from 'vuex'
import UserAPI from '@/services/api/Users.js'

export default {
	name: 'SuppressionItem',
	components: { UserAPI },
	props: {
		item: Object
	},
	data(){
		return {
			value: "",
			suppression_info: {},
			loading: true,
			disabled: false
		}
	},
	computed: {
		...mapGetters(['user_instance']),
	},
	methods: {
		async unlock(){
			const response = await UserAPI.deleteUserSuppressions(this.user_instance.id)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			} else {
				this.$q.notify(response)
				this.value = 'No suppression'
			}
		},
	},
	created(){
		UserAPI.getUserSuppressionStatus(this.user_instance.id).then(response => this.suppression_info = response)
	},
	watch: {
		'suppression_info': function(){
			this.value = `${this.suppression_info.status} ${this.suppression_info.description ? this.suppression_info.description: ""}`
			this.disabled = this.suppression_info.status === 'Not suppressed' ? true : false
			this.loading = false
		}
	}
}
</script>
