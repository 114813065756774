<template>
	<div class="header-wrapper">
		<h6 class="q-pa-none q-my-none">
			Permissions
		</h6>
		<div
			v-for="(item, index) in items"
			:key=index
		>
			<slider-item
				v-if="item.condition"
				:item=item
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SliderItem from '@/components/users/utils/SliderItem.vue'

export default {
	name: 'UserPermissions',
	components: { SliderItem },
	props: {
		item: Object
	},
	data() {
		return {
			items: []
		}
	},
	computed: {
		...mapGetters(['user_instance', 'current_CG']),
	},
	methods: {
		...mapActions(['updateUser']),
		createItems(highlight_item){
			this.items = [
				{
					'text': 'Self registered User',
					'value': this.user_instance.is_self_registered,
					'attribute': 'is_self_registered',
					'condition': this.user_instance.customer_permission == 'CONTACT',
					'highlight_item': highlight_item
				},
				{
					'text': 'Prevent deletion via import',
					'value': this.user_instance.is_remove_protected,
					'attribute': 'is_remove_protected',
					'condition': true
				},
				{
					'text': 'Active user (will be able to log in)',
					'value': this.user_instance.is_active,
					'attribute': 'is_active',
					'condition': JSON.parse(this.current_CG.customer_group.settings)['send_verification_email'] ||
						this.user_instance.customer_permission != "CONTACT"
				},
				{
					'text': 'Message recipient user (will receive messages)',
					'value': this.user_instance.is_message_recipient,
					'attribute': 'is_message_recipient',
					'condition': true
				},
				{
					'text': 'Allowed to assign own on call period',
					'value': this.user_instance.assign_on_call_periods,
					'attribute': 'assign_on_call_periods',
					'condition': (this.current_CG.root_customer_group_permissions.includes('Assign own On Call Period'))

				},
				{
					'text': 'Automatically receives all message reports',
					'value': this.user_instance.is_quick_report_recipient,
					'attribute': 'is_quick_report_recipient',
					'condition': this.user_instance.customer_permission == 'ADMIN',
					'highlight_item': highlight_item
				},

			]
			this.ready = true
		},
	},
	created() {
		this.createItems(false)
	},
	watch: {
		'user_instance.customer_permission': {
			handler(val) {
				this.createItems(true);
				if (val !== 'ADMIN'){
					this.updateUser({is_quick_report_recipient: false})
				}
				if (val !== 'CONTACT'){
					this.updateUser({is_self_registered: false})
				}
			}
		}
	}
}
</script>
