<template>
	<div >
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Settings for {{user_instance.address_string}}
		</h1>
		<basic-info class="bg-grey-1"/>
		<user-permissions class="bg-grey-1"/>
		<additional-info class="bg-grey-1"/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdditionalInfo from '@/components/users/utils/AdditionalInfo.vue'
import BasicInfo from '@/components/users/utils/BasicInfo.vue'
import UserPermissions from '@/components/users/utils/UserPermissions.vue'

export default {
	name: 'Settings',
	components: { AdditionalInfo, BasicInfo, UserPermissions },
	computed: {
		...mapGetters(['user_instance']),
	},
}
</script>

<style lang="scss">
.settings-item-wrapper {
	text-align: left;
	padding: 1rem;
	width: 100%;
}

.header-wrapper {
	text-align: left;
	border-radius: 0.375rem;
	padding: 1rem;
	margin-bottom: 1rem;
	width: 100%;
	border: 1px solid $grey-4;;
}
.highlight_item {
	animation: fade 10s forwards;
	background-color: rgb(253, 247, 226);
}

@keyframes fade {
	from {background-color:rgba(253, 247, 226, 1);}
	to {background-color:rgba(253, 247, 226, 0);}
}
</style>
