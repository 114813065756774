import { render, staticRenderFns } from "./CalendarItem.vue?vue&type=template&id=32df6f2a&"
import script from "./CalendarItem.vue?vue&type=script&lang=js&"
export * from "./CalendarItem.vue?vue&type=script&lang=js&"
import style0 from "./CalendarItem.vue?vue&type=style&index=0&id=32df6f2a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QDate from 'quasar/src/components/date/QDate.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QItemSection,QInput,QBtn,QDialog,QDate});qInstall(component, 'directives', {ClosePopup});
