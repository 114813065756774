<template>
	<q-item
		:class="{
			'settings-item-wrapper': true,
			'rounded-borders': true,
			'row': true,
			'justify-between': true,
			'relative-position': true,
		}"
	>
		<q-item-section>
			<q-item-label>{{ item.text }}</q-item-label>
		</q-item-section>
		<q-item-section avatar>
			<q-item-label>{{ item.value }}</q-item-label>
		</q-item-section>
	</q-item>
</template>
<script>

export default {
	name: 'TextItem',
	props: {
		item: Object
	},
}
</script>
