import { render, staticRenderFns } from "./UnlockItem.vue?vue&type=template&id=60cc1796&"
import script from "./UnlockItem.vue?vue&type=script&lang=js&"
export * from "./UnlockItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QItemSection,QItemLabel,QBtn});
