<template>
	<q-item :class="calendar_item_class">
		<q-item-section class="full-width">
			<q-input
				outlined
				v-model="year_start_date"
				hint="Select expiry date for the user. Format DD/MM/YYYY"
				:label="item.text"
				@click="date_dialog=true"
			>
				<template v-slot:append>
					<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_dialog=true"/>
				</template>
			</q-input>
			<q-dialog v-model="date_dialog">
				<q-date
					v-model="year_start_date"
					mask="DD/MM/YYYY"
					:options="allowedDays"
				>
					<div class="row items-center justify-end">
						<q-btn v-close-popup label="Close" color="primary" flat />
					</div>
				</q-date>
			</q-dialog>
		</q-item-section>
	</q-item>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'

export default {
	name: 'CalendarItem',
	props: {
		item: Object
	},
	data(){
		return {
			date_dialog: false,
			min_date: null,
			calendar_item_class: {
				'settings-item-wrapper': true
			}
		}
	},
	computed: {
		...mapGetters(['user_instance']),
		year_start_date: {
			get() {
				if (moment(this.user_instance[this.item.attribute], 'YYYY-MM-DD',true).isValid()){
					let value = this.user_instance[this.item.attribute]
					return `${value.split('-')[2]}/${value.split('-')[1]}/${value.split('-')[0]}`
				}
				return this.user_instance[this.item.attribute]
			},
			set(val) {
				if (!val){
					this.updateUser({[this.item.attribute]: null})
					return
				}
				if (moment(val, 'DD/MM/YYYY',true).isValid()){
					let formatted_val = `${val.split('/')[2]}-${val.split('/')[1]}-${val.split('/')[0]}`
					this.updateUser({[this.item.attribute]: formatted_val})
					return
				}
				this.updateUser({[this.item.attribute]: val})
			},
		},
	},
	methods: {
		...mapActions(['updateUser']),
		allowedDays(date){
			return date > this.min_date
		}
	},
	created(){
		this.min_date = new Date().toISOString().split('T')[0].replaceAll('-', '/')
		if (this.item.highlight_item){
			this.calendar_item_class['highlight_item'] = true
		}
	},
}
</script>
<style lang="scss">
	.date-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>
