<template>
	<q-item class="settings-item-wrapper">
		<q-item-section class="full-width">
			<q-input
				outlined
				v-model="value"
				:label="item.text"
				:name="item.text"
				:hint="'Please enter external ID'"
			/>
		</q-item-section>
	</q-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'InputItem',
	props: {
		item: Object
	},
	computed: {
		...mapGetters(['user_instance']),
		value: {
			get() {
				return this.user_instance[this.item.attribute]
			},
			set(val) {
				this.updateUser({[this.item.attribute]: val})
			},
		},
	},
	methods: {
		...mapActions(['updateUser']),
	},
}
</script>
