<template>
	<q-item
		:class="{
			'settings-item-wrapper': true,
			'rounded-borders': true,
			'row': true,
			'justify-between': true,
			'relative-position': true,
		}"
	>
		<q-item-section>
			<q-item-label>{{ item.text }}</q-item-label>
		</q-item-section>

		<q-item-section avatar>
			<q-btn
				outline
				icon-right="send"
				label="send"
				color="primary"
				@click="sendMail"
			/>
		</q-item-section>
	</q-item>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginAPI from '@/services/api/Login.js';

export default {
	name: 'PasswordResetItem',
	components: { LoginAPI },
	props: {
		item: Object
	},
	computed: {
		...mapGetters(['user_instance', 'logged_user']),
	},
	methods: {
		sendMail(){
			if (this.item.method == 'resetPassword'){
				this.resetPassword()
			} else if(this.item.method == 'reissue2FAQRCode') {
				this.reissue2FAQRCode()
			}
			else {
				this.reissueUserWelcomeEmail()
			}
		},
		async resetPassword(){
			const response = await LoginAPI[this.item.method]({
				"email": this.user_instance.email,
				"initiator_user_email": this.logged_user.email,
				'skip_recaptcha': true
			})
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			} else {
				this.$q.notify('The password reset email has been sent.')
			}
		},
		async reissueUserWelcomeEmail(){
			const response = await LoginAPI[this.item.method](this.user_instance.id)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			} else {
				this.$q.notify('The welcome email has been sent.')
			}
		},
		async reissue2FAQRCode() {
			const response = await LoginAPI[this.item.method](this.user_instance.id)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			} else {
				this.$q.notify('The 2FA QR code email has been sent.')
			}
		}
	},
}
</script>
