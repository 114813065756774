<template>
	<div class="settings-wrapper">
		<h6 class="q-pa-none q-my-none">
			Basic info
		</h6>
		<div
			v-for="(item, index) in items"
			:key=index 
		>
			<input-item
				v-if="item.input_item"
				:item=item
			/>
			<select-item 
				v-else-if="item.select_item"
				:item=item
			/>
			<calendar-item 
				v-else-if="item.calendar_item && item.condition"
				:item=item
			/>
			<time-zone-picker
				v-else-if="item.time_zone_picker"
				@timezoneSelected='timezoneSelected'
				:existing_settings="item.value"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputItem from '@/components/users/utils/InputItem.vue'
import SelectItem from '@/components/users/utils/SelectItem.vue'
import CalendarItem from '@/components/users/utils/CalendarItem.vue'
import DatasetAPI from '@/services/api/Datasets.js'
import TimeZonePicker from '@/components/utils/dropdowns/TimeZonePicker.vue'


export default {
	name: 'BasicInfo',
	components: { InputItem, SelectItem, CalendarItem, DatasetAPI, TimeZonePicker },
	props: {
		item: Object
	},
	data() {
		return {
			items: [],
			dataset_options: [],
			datasets_names_ids: {},
			datasets_ids_names: {}
		}
	},
	computed: {
		...mapGetters(['contact_fields', 'user_instance']),
	},
	methods: {
		...mapActions(['updateUser']),
		createItems(highlight_item){
			this.items = [
				{
					'text': 'Dataset',
					'value': '',
					'select_item': true,
					'show_tooltip': false,
					'options': this.dataset_options,
					'attribute': 'customer_group',
					'datasets_names_ids': this.datasets_names_ids,
					'datasets_ids_names': this.datasets_ids_names
				},
				{
					'text': 'Permission',
					'value': this.user_instance.customer_permission,
					'select_item': true,
					'show_tooltip': true,
					'options': [
						'ADMIN', 'OPERATOR', 'DATA', 'SENDER', 'CONTACT'
					],
					'attribute': 'customer_permission'
				},
				{
					'text': 'Auto expiry date for user',
					'value': this.user_instance.time_based_user_expiry_date,
					'calendar_item': true,
					'attribute': 'time_based_user_expiry_date',
					'condition': this.user_instance.customer_permission == 'CONTACT',
					'highlight_item': highlight_item
				},
				{
					'text': 'External ID',
					'value': this.user_instance.external_id,
					'input_item': true,
					'attribute': 'external_id'
				},
				{
					'text': 'User time zone',
					'value': this.user_instance.preferred_timezone,
					'time_zone_picker': true,
				},
			]
		},
		async setDatasetOptions(){
			let datasets = await DatasetAPI.getCustomerGroups()
			for (let dataset of datasets){
				this.datasets_names_ids[dataset.name] = dataset.id
				this.datasets_ids_names[dataset.id] = dataset.name
				this.dataset_options.push(dataset.name)
			}
		},
		timezoneSelected(timezone){
			this.updateUser({preferred_timezone: timezone})
		}
	},
	async created() {
		await this.setDatasetOptions()
		this.createItems(false)
	},
	watch: {	
		'user_instance.customer_permission': {
			handler(val) {
				this.createItems(true);
				if (val !== 'CONTACT'){
					this.updateUser({is_self_registered: ''})
				}
			}
		}
	}
}
</script>
