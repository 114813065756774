<template>
	<div v-if="show_additional_fields" class="settings-wrapper">
		<h6 class="q-pa-none q-my-none">
			Additional info
		</h6>
		<div
			v-for="(item, index) in items"
			:key=index
		>
			<unlock-item
				v-if="item.unlock_item && item.condition"
				:item=item
			/>
			<text-item
				v-else-if="item.text_item && item.condition"
				:item=item
			/>
			<suppression-item
				v-else-if="item.suppression_item && item.condition"
				:item=item
			/>
			<send-email-item
				v-else-if="item.send_email_item && item.condition"
				:item=item
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UnlockItem from '@/components/users/utils/UnlockItem.vue'
import SuppressionItem from '@/components/users/utils/SuppressionItem.vue'
import TextItem from '@/components/users/utils/TextItem.vue'
import SendEmailItem from '@/components/users/utils/SendEmailItem.vue'
import moment from 'moment-timezone'


export default {
	name: 'AdditionalInfo',
	components: { UnlockItem, TextItem, SuppressionItem, SendEmailItem },
	data() {
		return {
			items: [],
			show_additional_fields: true
		}
	},
	computed: {
		...mapGetters(['user_instance', 'current_CG']),
	},
	methods: {
		showAdditionalInfo(){
			let customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
			if (
				(!customer_group_settings.send_verification_email
				&& !this.user_instance.is_email_verified)
				&& this.user_instance.customer_permission == "CONTACT"
			) {
				this.show_additional_fields = false
			}
		},

		formatDate(date_string){
			date_string = moment.tz(date_string, "YYYY-MM-DDTHH:mm", "UTC")
			return date_string.format('DD/MM/YYYY')
		},
		createItems(){
			const failed_attempts = this.user_instance.failed_attempts
			this.items = [
				{
					'text': 'Failed login attempts',
					'value': "",
					'unlock_item': true,
					'method': 'deleteFailedLoginAttempts',
					'attribute': 'login_failures',
					'condition': this.user_instance.email && !this.user_instance.is_temporary
				},
				{
					'text': 'Password reset attempts',
					'value': "",
					'unlock_item': true,
					'method': 'deleteFailedPasswordResetAttempts',
					'attribute': 'password_failures',
					'condition':  this.user_instance.email && !this.user_instance.is_temporary
				},
				{
					'text': 'Email suppression status',
					'value': "",
					'suppression_item': true,
					'condition': this.user_instance.email && !this.user_instance.is_temporary
				},
				{
					'text': 'Date of registration',
					'value': this.formatDate(this.user_instance.created_at),
					'text_item': true,
					'condition': true
				},
				{
					'text': 'Last successful login',
					'value': this.formatDate(this.user_instance.last_activity),
					'text_item': true,
					'condition': this.user_instance.last_login ? true : false
				},
				{
					'text': 'Last login attempt',
					'value': failed_attempts ? this.formatDate(failed_attempts.last_failed_login_attempt) : '',
					'text_item': true,
					'condition': failed_attempts && failed_attempts.last_failed_login_attempt ? true : false
				},
				{
					'text': 'Last password change',
					'value': this.formatDate(this.user_instance.last_changed_password),
					'text_item': true,
					'condition': this.user_instance.last_changed_password && this.user_instance.email
				},
				{
					'text': 'Generate password reset email',
					'value': "",
					'send_email_item': true,
					'condition': this.user_instance.last_changed_password && this.user_instance.email,
					'method': 'resetPassword',
				},
				{
					'text': 'Re-issue welcome email to user',
					'value': "",
					'send_email_item': true,
					'condition': (!this.user_instance.last_login && this.user_instance.email) &&
						(this.user_instance.customer_permission !== "CONTACT"
						|| JSON.parse(this.current_CG.customer_group.settings)['send_verification_email']),
					'method': 'reissueUserWelcomeEmail',
				},
				{
					'text': 'Re-issue 2FA QR code to user',
					'value': "",
					'send_email_item': true,
					'condition': this.user_instance.email &&
						(this.user_instance.customer_permission !== "CONTACT"
						|| JSON.parse(this.current_CG.customer_group.settings)['send_verification_email']) &&
						this.current_CG.root_customer_group_permissions.includes('2FA'),
					'method': 'reissue2FAQRCode',
				}
			]
		}
	},
	created() {
		this.createItems()
		this.showAdditionalInfo()
	},
}
</script>
