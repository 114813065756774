<template>
	<div class="settings-item-wrapper full-width">
		<q-select
			v-model="selected_option"
			:options="item.options"
			:label="item.text"
			:hint="'Select 1 option'"
		/>
		<details-button
			v-if="item.show_tooltip"
			class="full-width col-12"
			:tooltip_content="permission_content"
			name="user-permission"
			:key="componentKey"
		/>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import helpers from '@/utils/helpers.js'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'SelectItem',
	components: {
		DetailsButton,
	},
	props: {
		item: Object
	},
	data() {
		return{
			permission_content: '',
			componentKey: 0,
		}
	},
	computed: {
		...mapGetters(['user_instance', 'cg_fields']),
		selected_option: {
			get() {
				let selected_option = ""
				if (this.item.attribute == 'customer_group'){
					selected_option = this.item.datasets_ids_names[this.user_instance[this.item.attribute]]
				} else {
					selected_option = this.user_instance[this.item.attribute]
					this.setDetailsWording(selected_option)
				}
				return selected_option
			},
			async set(val) {
				if (this.item.attribute == 'customer_group'){
					val = this.item.datasets_names_ids[val]
					await helpers.setFields(val)
				}
				this.updateUser({[this.item.attribute]: val})
			},
		},
	},
	methods: {
		...mapActions(['updateUser', 'updateDeviceFields', 'updateSelectDataFields', 'updateInputDataFields']),
		setDetailsWording(select_option){
			this.permission_content = ''
			if (select_option == 'ADMIN'){
				this.permission_content =
					'ADMINS can carry out every task in the service. All levels of users can receive messages ' +
					'and send replies.'
			}else if (select_option == 'OPERATOR'){
				this.permission_content =
					'OPERATORS can create, edit, and save message templates, as well as send messages, ' +
					'but can’t manage users. All levels of users can receive messages and send replies.'
			}else if (select_option == 'DATA'){
				this.permission_content =
					'DATA users can manage users but can’t send messages or access message ' +
					'templates. All levels of users can receive messages and send replies.'
			}else if (select_option == 'SENDER'){
				this.permission_content =
					'SENDERS can edit templates, message content and send a message but can’t save their ' +
					'changes and can’t manage users. All levels of users can receive messages and send replies.'
			}else if (select_option == 'CONTACT'){
				this.permission_content =
					'CONTACTS can receive messages and send replies.'
			}else{
				this.permission_content =
					'ADMINS can carry out every task in the service. OPERATORS can create, edit, and save message ' +
					'templates, as well as send messages, but can’t manage users. SENDERS can edit templates, message ' +
					'content and send a message but can’t save their changes and can’t manage users. DATA users can ' +
					'manage users but can’t send messages or access message templates. All levels of users can ' +
					'receive messages and send replies.'
			}
			this.componentKey += 1;
		}
	},
}
</script>
<style lang="scss" scoped>
	.wrap{
		flex-wrap: wrap;
	}
</style>
