<template>
	<q-item
		:class="{
			'settings-item-wrapper': true,
			'rounded-borders': true,
			'justify-between': true,
			'relative-position': true,
			'row': $q.screen.gt.sm,
			'column': $q.screen.lt.sm,
		}"
	>
		<q-item-section>
			<q-item-label>{{ item.text }}</q-item-label>
		</q-item-section>

		<q-item-section avatar :class="$q.screen.lt.sm ? 'no-padding' : ''">
			<q-item-label>
				{{ value }}
				<q-btn
					outline
					label="Unlock"
					color="primary"
					@click="unlock"
					class="q-ml-sm"
					:disable="disabled"
				/>
			</q-item-label>
		</q-item-section>
	</q-item>
</template>

<script>

import UserAPI from '@/services/api/Users.js'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UnlockItem',
	components: { UserAPI },
	props: {
		item: Object
	},
	data(){
		return {
			value: "",
			disabled: false
		}
	},
	computed: {
		...mapGetters(['user_instance'])
	},
	methods: {
		...mapActions(['updateUserFailedAttempts']),
		async unlock(){
			const response = await UserAPI[this.item.method](this.user_instance.id)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			} else {
				this.$q.notify(response)
				if (this.item.attribute){
					this.updateUserFailedAttempts({[this.item.attribute]: 0})
					this.createValue()
				}
			}
		},
		createValue(){
			const failed_attempts = this.user_instance.failed_attempts
			if (!failed_attempts){
				return
			}
			if(this.item.attribute === 'login_failures'){
				if (!failed_attempts.login_failures) {
						this.disabled=true
				}
				this.value = `${failed_attempts.login_failures} / ${failed_attempts.login_max_failures}`
			} else {
					if (!failed_attempts.password_failures) {
						this.disabled=true
				}
				this.value = `${failed_attempts.password_failures} / ${failed_attempts.password_max_failures}`
			}
		}
	},
	created(){
		this.createValue()
	}
}
</script>
