var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-item',{class:{
		'settings-item-wrapper': true,
		'rounded-borders': true,
		'row': true,
		'justify-between': true,
		'relative-position': true,
	}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(_vm.item.text))])],1),_c('q-item-section',{attrs:{"avatar":""}},[_c('q-btn',{attrs:{"outline":"","icon-right":"send","label":"send","color":"primary"},on:{"click":_vm.sendMail}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }